import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { LogoutComponent } from './sessions/logout/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReimbursementsComponent } from './reimbursements/reimbursements.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [MsalGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },
      {
        path: 'sessions',
        loadChildren: () => import('./sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Sessions', titleI18n: 'Sessions' },
      },
      {
        path: 'reimbursements',
        component: ReimbursementsComponent,
        canActivate: [NgxPermissionsGuard],
        data: { 
          title: 'Reimbursements',
          titleI18n: 'reimbursements',
          permissions: {
            only: 'USER',
            redirectTo: '/sessions/403'
          }
        },
        children: [
          {
            path: 'view/:id',
            component: ReimbursementsComponent,
          }
        ],
      },
      {
        path: 'finance',
        canActivate: [NgxPermissionsGuard],
        canActivateChild: [NgxPermissionsGuard],
        data: { 
          permissions: {
            only: 'FINANCE',
            redirectTo: '/sessions/403'
          }
        },
        loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule),
      },
      {
        path: 'staff-tools',
        canActivate: [NgxPermissionsGuard],
        canActivateChild: [NgxPermissionsGuard],
        data: { 
          permissions: {
            only: 'USER',
            redirectTo: '/sessions/403'
          }
        },
        loadChildren: () =>
          import('./staff-tools/staff-tools.module').then(m => m.StaffToolsModule),
      },
      {
        path: 'authorisation',
        canActivate: [NgxPermissionsGuard],
        canActivateChild: [NgxPermissionsGuard],
        data: { 
          permissions: {
            only: 'LEADER',
            redirectTo: '/sessions/403'
          }
        },
        loadChildren: () => import('./authorise/authorise.module').then(m => m.AuthoriseModule),
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'logout',
        component: LogoutComponent,
        data: { title: 'Logout', titleI18n: 'logout' },
      },
    ],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
