import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig} from '@ngx-formly/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reimbursements-notes',
  template: `
    <h2 mat-dialog-title>{{header}}</h2>
    <mat-dialog-content>
      <form [formGroup]="form">
        <formly-form [form]="form" [model]="model" [fields]="fields"> </formly-form>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="onOkay()" [disabled]="!form.valid">Ok</button>
    </mat-dialog-actions>
  `,
  styles: [``],
})
export class ReimbursementsNotesComponent {
  header: string = "";
  model: any = { notes: '' };
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'notes',
      type: 'textarea',
      templateOptions: {
        label: 'Notes',
        rows: 5,
        grow: false,
        required: true,
      },
    },
  ];

  constructor(private dialogRef: MatDialogRef<ReimbursementsNotesComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.header = data.header;
  }

  onOkay() {
    this.dialogRef.close(this.model.notes);
  }

  onCancel(){
    this.dialogRef.close();
  }
}