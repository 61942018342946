import { SharedModule } from '@shared/shared.module';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyValidation } from './formly-validation';
import { FormlyWrapperPanelComponent } from './wrappers/panel-wrapper';
import { FormlyWrapperExpanderComponent } from './wrappers/expander-wrapper';
import { FormlyFieldRepeatSectionComponent } from './types/repeat-section';
import { FormlyFieldTypeaheadComponent } from './types/filter-select';
import { FormlyFieldCurrencyComponent } from './types/currency';
import { FormlyFieldReceiptsComponent } from './types/receipts';

import { ReceiptsViewComponent } from './types/receipts/view.component';
import { FormlyFieldFileComponent } from './types/file';
/**
 * Formly global configuration
 */
const formlyModuleProviders = FormlyModule.forRoot({
  wrappers: [
      { name: 'panel', component: FormlyWrapperPanelComponent },
      { name: 'expander', component: FormlyWrapperExpanderComponent }
    ],
    types: [
      { name: 'repeat-section', component: FormlyFieldRepeatSectionComponent },
      { name: 'filter-select', component: FormlyFieldTypeaheadComponent, wrappers: ['form-field'] },
      { name: 'currency', component: FormlyFieldCurrencyComponent, wrappers: ['form-field'] },
      { name: 'receipts', component: FormlyFieldReceiptsComponent },
      { name: 'file', component: FormlyFieldFileComponent, wrappers: ['form-field'] },
    ],
    validators: [],
    validationMessages: []
}).providers;

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    FormlyWrapperPanelComponent, 
    FormlyWrapperExpanderComponent, 
    FormlyFieldRepeatSectionComponent,
    FormlyFieldTypeaheadComponent,
    FormlyFieldCurrencyComponent,
    FormlyFieldReceiptsComponent,
    ReceiptsViewComponent,
    FormlyFieldFileComponent
  ],
  providers: [FormlyValidation],
})
export class FormlyConfigModule {
  constructor(formlyValidation: FormlyValidation) {
    formlyValidation.init();
  }

  static forRoot(): ModuleWithProviders<FormlyConfigModule> {
    return {
      ngModule: FormlyConfigModule,
      providers: [formlyModuleProviders],
    };
  }
}
