import { FieldType } from '@ngx-formly/material/form-field';
import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'formly-field-file',
  template: `
    <input id="fileName" matInput [value]="selectedFileName" disabled/>
    <button #fileButton mat-raised-button (click)="openFileSelector();" class="file-button"><mat-icon>image</mat-icon></button>
    <input #fileInput id="fileInput" name="fileInput" type="file" accept=".jpg,.jpeg,.png" (change)="selectFiles($event)" />
  `,
  styles: [`
    #fileName {
      width: 90%;
    }
    #fileInput {
      display: none;
    }
    .file-button {
      float: rigth;
    }
  `],
})
export class FormlyFieldFileComponent extends FieldType {
  selectedFiles?: FileList;
  selectedFileName: string = '';

  @ViewChild('fileInput', { static: false })
  public fileSelector: ElementRef;

  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;

    if (this.selectedFiles && this.selectedFiles[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        var image = new Image();
        image.src = e.target.result;
        
        image.onload = () => {
          if (image.width < 1800 || image.height < 1240){
            this.formControl.setErrors({'size': false});
          }
        }

        let attachment = {
          fileName: this.selectedFiles[0].name,
          fileType: this.selectedFiles[0].type,
          base64: reader.result.toString().split(',')[1]
        }
        this.formControl.setValue(attachment);
      };

      reader.readAsDataURL(this.selectedFiles[0]);
      this.selectedFileName = this.selectedFiles[0].name;
    }
  }

  openFileSelector() {
    this.fileSelector.nativeElement.click();
  }
}
