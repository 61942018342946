import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-repeat-section',
    template: `
<div *ngFor="let field of field.fieldGroup; let i = index;" class="app-section-item">
<mat-card role="group">
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex class="header">{{ to.sectionTitle }} #{{i+1}}: </div>
        <div fxFlex="none">
          <button *ngIf="enabled" mat-icon-button class="mat-primary" type="button" matTooltip="Remove" (click)="remove(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <formly-field [field]="field"></formly-field>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="enabled">
  <button mat-raised-button type="button" (click)="add()" class="app-secondary-btn">
      <mat-icon>add_box</mat-icon> {{ to.addItem }}
  </button>
</div>
   `,
    styles: [`
      .header {
        font-weight: bold;
      }
    `]
})
export class FormlyFieldRepeatSectionComponent extends FieldArrayType implements OnInit {
  enabled = false;
  ngOnInit(): void {
    this.enabled = !this.formState.disabled
  }
}