import { PageRequest, Page } from '../page';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { SelectOption } from '../models/SelectOption';
import { Costcentre } from '../models/finance/costcentre';
import { ServiceResponse } from '../ServiceResponse';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

export interface CostcentreQuery {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CostcentreService {
  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.SERVER_ORIGIN;
    this.myApiUrl = 'api/costcentre';
  }

  page(request: PageRequest<Costcentre>, query: CostcentreQuery): Observable<Page<Costcentre>> {
    var params: HttpParams = new HttpParams();

    var filters = [];
    if (query.code) {
      query.code = query.code.toLowerCase();
      filters.push('code@=' + query.code);
    }
    if (query.name) {
      query.name = query.name.toLowerCase();
      filters.push('name@=' + query.name);
    }
    if (filters.length){
      params = params.set('filters', filters.join(', '));
    }
    
    if (request.page > 0) {
      params = params.set('page', (request.page + 1).toString());
    }

    if (request.size > 0) {
      params = params.set('pageSize', request.size.toString());
    }

    if (request.sort) {
      params = params.set(
        'sorts',
        (request.sort.direction == 'asc' ? '' : '-') + request.sort.active.toString()
      );
    }

    return this.http
      .get<ServiceResponse<Costcentre[]>>(`${this.myAppUrl}/${this.myApiUrl}/getAll`, { params })
      .pipe(
        map(response => {
          const page: Page<Costcentre> = {
            content: response.data,
            number: response.page - 1,
            size: response.pageSize,
            totalElements: response.count,
          };
          return page;
        })
      );
  }

  add(costcentre: Costcentre): Observable<Costcentre> {
    return this.http
      .post<ServiceResponse<Costcentre>>(
        `${this.myAppUrl}/${this.myApiUrl}`, 
        JSON.stringify(costcentre), 
        this.httpOptions)
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  update(costcentre: Costcentre): Observable<Costcentre> {
    return this.http.put<ServiceResponse<Costcentre>>(
        `${this.myAppUrl}/${this.myApiUrl}`, 
        JSON.stringify(costcentre), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  delete(costcentre: Costcentre): Observable<Costcentre> {
    return this.http.delete<ServiceResponse<Costcentre>>(`${this.myAppUrl}/${this.myApiUrl}/${costcentre.id}`)
      .pipe(
        retry(1),
        map(result => costcentre)
      );
  }

  checkCostcentre(code: string): Observable<boolean> {
    return this.http.get<ServiceResponse<boolean>>(`${this.myAppUrl}/${this.myApiUrl}/checkCostcentre/${code}`)
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  getOptions(): Observable<SelectOption[]> {
    return this.http
      .get<ServiceResponse<SelectOption[]>>(`${this.myAppUrl}/${this.myApiUrl}/getOptions`)
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }
}