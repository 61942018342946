import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageViewerComponent } from '@shared/components/image-viewer/image-viewer.component';
import { ServiceResponse } from '@shared/data/ServiceResponse';
import { map, retry } from 'rxjs/operators';

@Component({
  selector: 'app-receipts-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})
export class ReceiptsViewComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ReceiptsViewComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.imageUrl = this.data.image;
  }

  @ViewChild(ImageViewerComponent) viewer:ImageViewerComponent;

  imageUrl: string;
  images: string[];
  imageIndex = 0;
  loading: boolean = true;

  ngOnInit() {
    this.http
    .get<ServiceResponse<string[]>>(this.imageUrl)
    .pipe(
      retry(1),
      map(result => result.data)
    )
    .subscribe(result => {
      this.loading = false;
      this.images = result;
    });
  }

  Close() {
    this.images = [];
    this.imageIndex = 0;
    this.loading = true;
    this.dialogRef.close();
  }
}
