<mat-toolbar class="matero-toolbar">
  <button mat-icon-button class="matero-toolbar-button" *ngIf="showToggle"
          (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <app-branding *ngIf="showBranding"></app-branding>
  <span fxFlex></span>

<!--

  <button mat-icon-button class="matero-toolbar-button">
    <mat-icon>search</mat-icon>
  </button>

  <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleFullscreen()">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <app-notification fxHide.lt-sm></app-notification>

  <app-translate></app-translate>

-->

  <app-user></app-user>

<!--

  <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleSidenavNotice.emit()">
    <mat-icon>list</mat-icon>
  </button>

-->

</mat-toolbar>