<h2 mat-dialog-title>View Receipt:</h2>
<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center" fxFill>
  <mat-spinner diameter="50"></mat-spinner>
</div>
<div *ngIf="!loading" class="dialog-image-viewer">
  <image-viewer  [src]="images" [(index)]="imageIndex"></image-viewer>
</div>

<mat-dialog-actions align="end">
  <button mat-button (click)="Close()">Close</button>
</mat-dialog-actions>