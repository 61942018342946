import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
      <p>{{ to.text }}</p>
      <ng-container #fieldComponent></ng-container>
  `,
})
export class FormlyWrapperPanelComponent extends FieldWrapper {}