import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './sessions/logout/logout.component';
import { ReimbursementsComponent } from './reimbursements/reimbursements.component';
import { ReimbursementsEditComponent } from './reimbursements/edit/edit.component';
import { ReimbursementsNotesComponent } from './reimbursements/edit/notes.component';
import { ReimbursementsProcessComponent } from './reimbursements/edit/process.component';

const COMPONENTS = [
  DashboardComponent, 
  ReimbursementsComponent, 
  ReimbursementsEditComponent,
  ReimbursementsNotesComponent,
  ReimbursementsProcessComponent,
  LogoutComponent];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class RoutesModule {}
