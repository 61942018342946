import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'formly-field-typeahead',
  template: `
    <mtx-select [items]="to.options| async"
      [placeholder]="to.placeholder"
      bindLabel="name"
      bindValue="id"
      groupBy="group"
      appendTo="body"
      [formControl]="formControl">
      <ng-template ng-optgroup-tmp let-item="item">
        {{item.group || 'Unnamed group'}}
    </ng-template>
    </mtx-select>
  `,
})
export class FormlyFieldTypeaheadComponent extends FieldType {
  
}