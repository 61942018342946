import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MtxDialog } from '@ng-matero/extensions';
import { FieldArrayType } from '@ngx-formly/core';
import { Receipt } from '@shared/data/models/finance/receipt';
import { ServiceResponse } from '@shared/data/ServiceResponse';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { ReceiptsViewComponent } from './receipts/view.component';

@Component({
  selector: 'formly-field-receipts',
  template: `
    <div *ngIf="enabled"
      id="drag_zone"
      class="file-upload-wrapper"
      (drop)="dropzoneHovered = false; dropFiles($event)"
      (dragover)="dragOverHandler($event)"
      (dragenter)="dropzoneHovered = true"
      (dragleave)="dropzoneHovered = false"
      [class.drag-active]="dropzoneHovered"
    >
      Drop receipts here (files allowed: *.pdf, *.png, *.jpg), or click
      <button
        #fileButton
        mat-raised-button
        type="button"
        (click)="openFileSelector();"
        class="receipt-file-button"
      >
        Browse Files
      </button>
      <input
        type="file"
        #fileSelector
        accept=".jpg,.png,.pdf"
        multiple
        (change)="selectFiles($event)"
        class="receipt-file-selector"
      />
    </div>

    <mat-list>
      <mat-list-item
        *ngFor="let file of this.selectedFiles"
        [class.upload-success]="file.uploadCompleted"
      >
        <mat-icon matListIcon>request_quote</mat-icon>
        <p *ngIf="file.uploadCompleted; else Uploading" matLine>
          <a (click)="viewFile(file)" [routerLink]="">{{ file.filename }}</a>
        </p>
        <ng-template #Uploading
          ><p>{{ file.filename }}</p></ng-template
        >

        <mat-progress-bar mode="determinate" [value]="file.uploadedPercent"></mat-progress-bar>

        <button *ngIf="enabled" mat-icon-button type="button" matTooltip="Remove" (click)="deleteFile(file)">
          <mat-icon>close</mat-icon>
        </button>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  `,
  styles: [
    `
      #drag_zone {
        position: relative;
        width: 100%;
        padding: 5px;
        text-align: center;
        background-color: #fafafa;
        border: 2px dashed #eeeeee;
        border-radius: 8px;
        color: rgba(0, 0, 0, 0.87);
      }
      .receipt-file-selector {
        display: none;
      }
      .receipt-file-button {
        display: block;
        margin: 10px auto 5px auto;
      }
      .drag-active {
        background-color: #dfeff5 !important;
        border: 2px dashed #3285a8 !important;
      }
      .upload-success mat-progress-bar {
        display: none;
      }
    `,
  ],
})
export class FormlyFieldReceiptsComponent extends FieldArrayType implements OnInit {
  dropzoneHovered = false;
  selectedFiles = [];
  enabled = true;

  @ViewChild('fileSelector', { static: false })
  public fileSelector: ElementRef;

  myAppUrl: string;
  myApiUrl: string;

  sub: Subscription;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private mtxDialog: MtxDialog
  ) {
    super();

    this.myAppUrl = environment.SERVER_ORIGIN;
    this.myApiUrl = 'api/document';
  }

  openFileSelector() {
    this.fileSelector.nativeElement.click();
  }

  selectFiles(ev: Event) {
    if (ev.target) {
      const items = (ev.target as HTMLInputElement).files || ([] as any);
      for (let item of items) {
        if (this.checkFile(item.name)) {
          let obj = {
            filename: item.name,
            selectedFile: item,
            fileId: `${item.name}-${item.lastModified}`,
            uploadCompleted: false,
            uploadedPercent: 0,
          };
          this.selectedFiles.push(obj);
        }
      }
      this.uploadFiles();
    }
  }

  ngOnInit(): void {
    this.enabled = !this.formState.disabled;
    if(this.formControl.length > 0){
      for(let item of this.model){
        let obj = {
          filename: item.filename,
          fileId: item.fileId,
          uploadCompleted: true,
          uploadedPercent: 100,
        };
        this.selectedFiles.push(obj);
      }
    } else {
      this.sub = this.formControl.valueChanges.subscribe(val => {
        for (let item of val) {
          var add_this: boolean = true;
  
          if (item.fileId != undefined) {
            this.selectedFiles.forEach(el => {
              if (el.fileId == item.fileId) {
                add_this = false;
              }
            });
  
            if (add_this) {
              let obj = {
                filename: item.filename,
                fileId: item.fileId,
                uploadCompleted: true,
                uploadedPercent: 100,
              };
              this.selectedFiles.push(obj);
            }
          }
        }
        this.sub.unsubscribe();
      });
    }

  }

  dropFiles(ev: DragEvent) {
    // Prevent default behavior(file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          let file = ev.dataTransfer.items[i].getAsFile();
          if (this.checkFile(file.name)) {
            let obj = {
              filename: file.name,
              selectedFile: file,
              fileId: `${file.name}-${file.lastModified}`,
              uploadCompleted: false,
              uploadedPercent: 0,
            };
            this.selectedFiles.push(obj);
          }
        }
      }
      this.uploadFiles();
    }
  }

  checkFile(file: string): boolean {
    var ext = this.getExtension(file);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'png':
      case 'pdf':
        return true;
    }
    this.snackBar.open(`Filetype of file "${file}" is not allowed.`, 'Dismiss', { duration: 3000 });
    return false;
  }

  getExtension(filename: string): string {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  dragOverHandler(ev: DragEvent) {
    console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    ev.stopPropagation();
  }

  uploadFiles() {
    this.selectedFiles.forEach(file => {
      if (file.uploadedPercent == 0) {
        const formData = new FormData();

        formData.append('file', file.selectedFile);

        const req = new HttpRequest('POST', `${this.myAppUrl}/${this.myApiUrl}`, formData, {
          reportProgress: true,
        });

        this.http.request(req).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            file.uploadedPercent = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            var receipt: Receipt = (<ServiceResponse<Receipt>>event.body).data;

            file.fileId = receipt.fileId;
            file.uploadCompleted = true;

            this.add(-1, {
              fileId: file.fileId,
              filename: file.filename,
            });
          }
        });
      }
    });
  }

  deleteFile(file) {
    this.remove(this.selectedFiles.indexOf(file));
    this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
  }

  viewFile(file) {
    this.mtxDialog.originalOpen(ReceiptsViewComponent, {
      disableClose: false,
      autoFocus: true,
      width: '800px',
      data: { image: `${this.myAppUrl}/${this.myApiUrl}/${file.fileId}` },
    });
  }
}
