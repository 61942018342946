import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  
  constructor(private router: Router, private authService: MsalService) {
    if (this.authService.instance.getAllAccounts().length) {
        this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {}
}