import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { GraphService, User } from '@shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DashboardComponent implements OnInit {
  user: User;

  // keep reference of all subscriptions, do destroy later
  ngDestroy$ = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private graph: GraphService,
    private authService: MsalService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    /*this.authService.handleRedirectObservable().subscribe({
      next: result => console.log(result),
      error: error => console.log(error),
    });*/

    this.graph
      .getUserProfile()
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(result => {
        this.user = result;
    });


  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
