import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectOption } from '../models/SelectOption';
import { ServiceResponse } from '../ServiceResponse';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.SERVER_ORIGIN;
    this.myApiUrl = 'api/person';
  }

  getOptions(): Observable<SelectOption[]> {
    return this.http
      .get<ServiceResponse<SelectOption[]>>(`${this.myAppUrl}/${this.myApiUrl}/getOptions`)
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }

  getLeaderOptions(): Observable<SelectOption[]> {
    return this.http
      .get<ServiceResponse<SelectOption[]>>(`${this.myAppUrl}/${this.myApiUrl}/getLeaderOptions`)
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }
}
