import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/';
const GRAPH_PROFILE = GRAPH_ENDPOINT + 'me';
const GRAPH_PHOTO = GRAPH_ENDPOINT + 'me/photo/$value';
const GRAPH_EMAIL =  environment.SERVER_ORIGIN + "/api/email";

export class User {
  id: string;
  userPrincipalName: string;
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
  mobilePhone: string;
  avatar?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  // Retrieve user profile information
  _user: Observable<User>;
  getUserProfile(): Observable<User> {
    if (!this._user) {
      this._user = this.http.get(GRAPH_PROFILE).pipe(map(profile => <User>profile));
    }
    return this._user;
  }

  // Retrieve user profile image blob
  _photo: Observable<any>;
  getUserPhoto(): Observable<any> {
    if (!this._photo) {
      this._photo = this.http
        .get(GRAPH_PHOTO, { responseType: 'blob' })
        .pipe(
          map(response => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response))),
          catchError((err) => {
            return of("/assets/images/avatar.jpg");
          })
        );
    }
    return this._photo;
  }

  sendEmail(toEmail: string, subject: string, message: string, attachment: {fileName: string, fileType: string, base64: string}): Observable<any> {
    let mail = {
      toEmail: toEmail,
      subject: subject,
      message: message,
      attachments: [
        attachment,
      ]
    }

    return this.http.post(GRAPH_EMAIL, mail)
  }
}
