import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MenuService } from './menu.service';
import { SettingsService } from './settings.service';
import { GraphService } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(
    private menuService: MenuService,
    private http: HttpClient,
    private settings: SettingsService,
    private graph: GraphService
  ) {}

  load(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get('assets/data/menu.json?_t=' + Date.now())
        .pipe(
          catchError(res => {
            resolve();
            return throwError(res);
          })
        )
        .subscribe(
          (res: any) => {
            this.menuService.addNamespace(res.menu, 'menu');
            this.menuService.set(res.menu);
          },
          () => {
            reject();
          },
          () => {
            resolve();
          }
        );
    });
  }
}
