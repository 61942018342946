import { PageRequest, Page } from '../page';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { Reimbursement } from '../models/finance/reimbursement';
import { ServiceResponse } from '../ServiceResponse';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

export interface ReimbursementQuery {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReimbursementService {
  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.SERVER_ORIGIN;
    this.myApiUrl = 'api/reimbursement';
  }

  page(request: PageRequest<Reimbursement>, query: ReimbursementQuery): Observable<Page<Reimbursement>> {
    var params: HttpParams = new HttpParams();

    var filters = [];
    if (query.code) {
      query.code = query.code.toLowerCase();
      filters.push('code@=' + query.code);
    }
    if (query.name) {
      query.name = query.name.toLowerCase();
      filters.push('name@=' + query.name);
    }
    if (filters.length){
      params = params.set('filters', filters.join(', '));
    }
    
    if (request.page > 0) {
      params = params.set('page', (request.page + 1).toString());
    }

    if (request.size > 0) {
      params = params.set('pageSize', request.size.toString());
    }

    if (request.sort) {
      params = params.set(
        'sorts',
        (request.sort.direction == 'asc' ? '' : '-') + request.sort.active.toString()
      );
    }

    return this.http
      .get<ServiceResponse<Reimbursement[]>>(`${this.myAppUrl}/${this.myApiUrl}/getAll`, { params })
      .pipe(
        map(response => {
          const page: Page<Reimbursement> = {
            content: response.data,
            number: response.page - 1,
            size: response.pageSize,
            totalElements: response.count,
          };
          return page;
        })
      );
  }

  authorisations(request: PageRequest<Reimbursement>, query: ReimbursementQuery): Observable<Page<Reimbursement>> {
    var params: HttpParams = new HttpParams();

    var filters = [];
    if (query.code) {
      query.code = query.code.toLowerCase();
      filters.push('code@=' + query.code);
    }
    if (query.name) {
      query.name = query.name.toLowerCase();
      filters.push('name@=' + query.name);
    }
    if (filters.length){
      params = params.set('filters', filters.join(', '));
    }
    
    if (request.page > 0) {
      params = params.set('page', (request.page + 1).toString());
    }

    if (request.size > 0) {
      params = params.set('pageSize', request.size.toString());
    }

    if (request.sort) {
      params = params.set(
        'sorts',
        (request.sort.direction == 'asc' ? '' : '-') + request.sort.active.toString()
      );
    }

    return this.http
      .get<ServiceResponse<Reimbursement[]>>(`${this.myAppUrl}/${this.myApiUrl}/authorisations`, { params })
      .pipe(
        map(response => {
          const page: Page<Reimbursement> = {
            content: response.data,
            number: response.page - 1,
            size: response.pageSize,
            totalElements: response.count,
          };
          return page;
        })
      );
  }

  authhistory(request: PageRequest<Reimbursement>, query: ReimbursementQuery): Observable<Page<Reimbursement>> {
    var params: HttpParams = new HttpParams();

    var filters = [];
    if (query.code) {
      query.code = query.code.toLowerCase();
      filters.push('code@=' + query.code);
    }
    if (query.name) {
      query.name = query.name.toLowerCase();
      filters.push('name@=' + query.name);
    }
    if (filters.length){
      params = params.set('filters', filters.join(', '));
    }
    
    if (request.page > 0) {
      params = params.set('page', (request.page + 1).toString());
    }

    if (request.size > 0) {
      params = params.set('pageSize', request.size.toString());
    }

    if (request.sort) {
      params = params.set(
        'sorts',
        (request.sort.direction == 'asc' ? '' : '-') + request.sort.active.toString()
      );
    }

    return this.http
      .get<ServiceResponse<Reimbursement[]>>(`${this.myAppUrl}/${this.myApiUrl}/authorisations/history`, { params })
      .pipe(
        map(response => {
          const page: Page<Reimbursement> = {
            content: response.data,
            number: response.page - 1,
            size: response.pageSize,
            totalElements: response.count,
          };
          return page;
        })
      );
  }

  processing(request: PageRequest<Reimbursement>, query: ReimbursementQuery): Observable<Page<Reimbursement>> {
    var params: HttpParams = new HttpParams();

    var filters = [];
    if (query.code) {
      query.code = query.code.toLowerCase();
      filters.push('code@=' + query.code);
    }
    if (query.name) {
      query.name = query.name.toLowerCase();
      filters.push('name@=' + query.name);
    }
    if (filters.length){
      params = params.set('filters', filters.join(', '));
    }
    
    if (request.page > 0) {
      params = params.set('page', (request.page + 1).toString());
    }

    if (request.size > 0) {
      params = params.set('pageSize', request.size.toString());
    }

    if (request.sort) {
      params = params.set(
        'sorts',
        (request.sort.direction == 'asc' ? '' : '-') + request.sort.active.toString()
      );
    }

    return this.http
      .get<ServiceResponse<Reimbursement[]>>(`${this.myAppUrl}/${this.myApiUrl}/processing`, { params })
      .pipe(
        map(response => {
          const page: Page<Reimbursement> = {
            content: response.data,
            number: response.page - 1,
            size: response.pageSize,
            totalElements: response.count,
          };
          return page;
        })
      );
  }

  processinghistory(request: PageRequest<Reimbursement>, query: ReimbursementQuery): Observable<Page<Reimbursement>> {
    var params: HttpParams = new HttpParams();

    var filters = [];
    if (query.code) {
      query.code = query.code.toLowerCase();
      filters.push('code@=' + query.code);
    }
    if (query.name) {
      query.name = query.name.toLowerCase();
      filters.push('name@=' + query.name);
    }
    if (filters.length){
      params = params.set('filters', filters.join(', '));
    }
    
    if (request.page > 0) {
      params = params.set('page', (request.page + 1).toString());
    }

    if (request.size > 0) {
      params = params.set('pageSize', request.size.toString());
    }

    if (request.sort) {
      params = params.set(
        'sorts',
        (request.sort.direction == 'asc' ? '' : '-') + request.sort.active.toString()
      );
    }

    return this.http
      .get<ServiceResponse<Reimbursement[]>>(`${this.myAppUrl}/${this.myApiUrl}/processing/history`, { params })
      .pipe(
        map(response => {
          const page: Page<Reimbursement> = {
            content: response.data,
            number: response.page - 1,
            size: response.pageSize,
            totalElements: response.count,
          };
          return page;
        })
      );
  }

  get(id: number): Observable<Reimbursement> {
    return this.http
    .get<ServiceResponse<Reimbursement>>(`${this.myAppUrl}/${this.myApiUrl}/${id}`)
    .pipe(
      retry(1),
      map(result => result.data)
    );
  }

  add(reimbursement: Reimbursement): Observable<Reimbursement> {
    return this.http
      .post<ServiceResponse<Reimbursement>>(
        `${this.myAppUrl}/${this.myApiUrl}`, 
        JSON.stringify(reimbursement), 
        this.httpOptions)
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  update(reimbursement: Reimbursement, notes?: string): Observable<Reimbursement> {
    if(notes){
      return this.http.put<ServiceResponse<Reimbursement>>(
        `${this.myAppUrl}/${this.myApiUrl}/Finance/Update`, 
        JSON.stringify({reimbursement: reimbursement, notes: notes}), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
    }else{
      return this.http.put<ServiceResponse<Reimbursement>>(
        `${this.myAppUrl}/${this.myApiUrl}`, 
        JSON.stringify(reimbursement), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
    }
  }

  submit(reimbursement: Reimbursement): Observable<Reimbursement> {
    return this.http.post<ServiceResponse<Reimbursement>>(
        `${this.myAppUrl}/${this.myApiUrl}/submit`, 
        JSON.stringify(reimbursement), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  authorise(reimbursement: Reimbursement): Observable<Reimbursement> {
    var t = JSON.stringify([{id: reimbursement.id}]);
    return this.http.post<ServiceResponse<Reimbursement>>(
        `${this.myAppUrl}/${this.myApiUrl}/authorise`, 
        JSON.stringify({id: reimbursement.id}), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  process(reimbursement: Reimbursement, data: {date: Date, account: number, partner: number}): Observable<Reimbursement> {
    var t = JSON.stringify([{id: reimbursement.id}]);
    return this.http.post<ServiceResponse<Reimbursement>>(
        `${this.myAppUrl}/${this.myApiUrl}/finance/process`, 
        JSON.stringify({reimbursement: reimbursement.id, date: data.date, account: data.account, partner: data.partner}), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  decline(reimbursement: Reimbursement, notes: string, finance: boolean): Observable<Reimbursement> {
    return this.http.post<ServiceResponse<Reimbursement>>(
        `${this.myAppUrl}/${this.myApiUrl}/decline`, 
        JSON.stringify({id: reimbursement.id, notes: notes, finance: finance}), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  delete(reimbursement: Reimbursement): Observable<Reimbursement> {
    return this.http.delete<ServiceResponse<Reimbursement>>(`${this.myAppUrl}/${this.myApiUrl}/${reimbursement.id}`)
      .pipe(
        retry(1),
        map(result => reimbursement)
      );
  }

  public getStatusText(reimbursement: Reimbursement): string {
    switch (reimbursement.status[reimbursement.status.length - 1].type) {
      case 1: // STATUS_USER_SAVED
        return 'Reimbursement saved.';
      case 2: // STATUS_USER_SUBMITTED
        return 'Reimbursement submitted.';
      case 3: // STATUS_LEADER_AUTHORISED
        return 'Reimbursement authorised.';
      case 4: // STATUS_LEADER_REJECTED
        return 'Reimbursement declined.';
      case 5: // STATUS_FINANCE_PROCESSED
        return 'Reimbursement processed.';
      case 6: // STATUS_FINANCE_DECLINED
        return 'Reimbursement declined.';
      case 7: // STATUS_FINANCE_EDITED
        return 'Edited by Finance, see notes for details.';
    }
  }

  public canEdit(reimbursement: Reimbursement): boolean {
    switch (reimbursement.status[reimbursement.status.length - 1].type) {
      case 1: // STATUS_USER_SAVED
        return true;
      case 2: // STATUS_USER_SUBMITTED
        return false;
      case 3: // STATUS_LEADER_AUTHORISED
        return false;
      case 4: // STATUS_LEADER_REJECTED
        return true;
      case 5: // STATUS_FINANCE_PROCESSED
        return false;
      case 6: // STATUS_FINANCE_DECLINED
        return true;
      case 7: // STATUS_FINANCE_EDITED
        return false;
    }

  }

}
