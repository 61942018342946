<page-header></page-header>

<mat-toolbar>
  <button mat-stroked-button color="primary" (click)="add()" matTooltip="New reimbursement request">
    <mat-icon>add_box</mat-icon> New reimbursement request
  </button>
</mat-toolbar>

<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center" fxFill>
  <mat-spinner diameter="50"></mat-spinner>
</div>

<mtx-grid
  *ngIf="data.page$ | async as page"
  [data]="page.content"
  [columns]="columns"
  [multiSelectable]="false"
  [rowSelectable]="false"
  [length]="page.totalElements"
  [pageOnFront]="false"
  [pageIndex]="page.number"
  [pageSize]="page.size"
  [pageSizeOptions]="[10, 20, 50]"
  (sortChange)="data.sortBy($event)"
  (page)="data.fetch($event)"
>
</mtx-grid>