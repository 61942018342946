import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyField, FormlyFieldConfig} from '@ngx-formly/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService, options, PartnerService } from '@shared';
import { Reimbursement } from '@shared/data/models/finance/reimbursement';

@Component({
  selector: 'app-reimbursements-process',
  template: `
    <h2 mat-dialog-title>Process Reimbursement R{{"000000" + reimbursement.id | slice:-6}}</h2>
    <mat-dialog-content>
      <form [formGroup]="form">
        <formly-form [form]="form" [model]="model" [fields]="fields"> </formly-form>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="onProcess()" [disabled]="!form.valid">Process</button>
    </mat-dialog-actions>
  `,
  styles: [``],
})
export class ReimbursementsProcessComponent implements OnInit {
  reimbursement: Reimbursement;
  model: any = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'date',
      type: 'datepicker',
      templateOptions: {
        label: 'date',
        required: true,
        datepickerOptions: {
          startView: 'month',
        },
      },
      defaultValue: new Date(),
    },
    {
      key: 'account',
      type: 'select',
      templateOptions: {
        label: 'Account',
        required: true,
        options: this.accounts.getProcessOptions(),
        valueProp: 'id',
        labelProp: 'name',
        groupProp: 'none',
      },
    },
    {
      key: 'partner',
      type: 'filter-select',
      templateOptions: {
        label: 'Partner',
        required: true,
        options: this.partners.getOptions(),
        valueProp: 'id',
        labelProp: 'name',
        groupProp: 'none',
      },
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<ReimbursementsProcessComponent>,
    private accounts: AccountService,
    private partners: PartnerService,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.reimbursement = data.reimbursement;
  }
  ngOnInit(): void {
    this.partners.getUserPartner(this.reimbursement.user).subscribe(partner => {
      this.form.get('partner').setValue(partner.id);
    });
    this.accounts.getProcessOptions().subscribe(o => {
      this.form.get('account').setValue(o[0].id);
    });
  }

  onProcess() {
    this.dialogRef.close(this.model);
  }

  onCancel(){
    this.dialogRef.close();
  }
}