<div
  class="img-container"
  [style.backgroundColor]="config.containerBackgroundColor"
  (wheel)="scrollZoom($event)"
  (dragover)="onDragOver($event)"
>
  <img
    [src]="src[index]"
    [ngStyle]="style"
    alt="Image not found..."
    (dragstart)="onDragStart($event)"
    (load)="onLoad()"
    (loadstart)="onLoadStart()"
  />

  <div></div>
  <div class="spinner-container" *ngIf="loading">
    <div class="spinner"></div>
  </div>

  <button
    type="button"
    [class]="config.btnClass"
    *ngIf="config.btnShow.rotateCounterClockwise"
    (click)="rotateCounterClockwise()"
  >
    <mat-icon>{{config.btnIcons.rotateCounterClockwise}}</mat-icon>
  </button>
  <button
    type="button"
    [class]="config.btnClass"
    *ngIf="config.btnShow.rotateClockwise"
    (click)="rotateClockwise()"
  >
    <mat-icon>{{config.btnIcons.rotateClockwise}}</mat-icon>
  </button>

  <button
    type="button"
    [class]="config.btnClass"
    *ngIf="config.btnShow.zoomOut"
    (click)="zoomOut()"
  >
    <mat-icon>{{config.btnIcons.zoomOut}}</mat-icon>
  </button>
  <button
    type="button"
    [class]="config.btnClass"
    *ngIf="config.btnShow.zoomIn"
    (click)="zoomIn()"
  >
    <mat-icon>{{config.btnIcons.zoomIn}}</mat-icon>
  </button>

  <button
    type="button"
    [class]="config.btnClass"
    *ngFor="let cBtn of config.customBtns"
    (click)="fireCustomEvent(cBtn.name, index)"
  >
    <mat-icon>{{cBtn.icon}}</mat-icon>
  </button>

  <button
    type="button"
    id="ngx-fs-btn"
    [class]="config.btnClass"
    (click)="toggleFullscreen()"
    *ngIf="config.allowFullscreen"
  >
    <mat-icon>{{config.btnIcons.fullscreen}}</mat-icon>
  </button>

  <div class="nav-button-container" *ngIf="src.length > 1">
    <button
      type="button"
      [class]="config.btnClass"
      (click)="prevImage($event)"
      [disabled]="index === 0"
    >
      <mat-icon>{{config.btnIcons.prev}}</mat-icon>
    </button>
    <button
      type="button"
      [class]="config.btnClass"
      (click)="nextImage($event)"
      [disabled]="index === src.length - 1"
    >
      <mat-icon>{{config.btnIcons.next}}</mat-icon>
    </button>
  </div>
</div>