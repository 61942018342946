import { Invoice } from "./invoice";
import { Status } from "./status";

export class Reimbursement {
    constructor(
        public id?: number,
        public user?: number,
        public userName?: string,
        public leader?: number,
        public status: Array<Status> = [],
        public invoices: Array<Invoice> = [ new Invoice() ],
        public updatedDate?: Date,
    ) { }
}