import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService, SettingsService } from '@core';
import { GraphService, User } from '@shared';
import { MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user',
  template: `
    <button
      class="matero-toolbar-button matero-avatar-button"
      mat-button
      [matMenuTriggerFor]="menu"
    >
      <img class="matero-avatar" [src]="avatar" width="32" alt="avatar" />
      <span class="matero-username" fxHide.md >{{ user?.displayName }}</span>
    </button>

    <mat-menu #menu="matMenu">
      <!--<button routerLink="/profile/overview" mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'user.profile' | translate }}</span>
      </button>-->
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'user.logout' | translate }}</span>
      </button>
    </mat-menu>
  `,
})
export class UserComponent implements OnInit, OnDestroy {
  user: User;
  avatar: any;

  // keep reference of all subscriptions, do destroy later
  ngDestroy$ = new Subject();

  constructor(
    private router: Router,
    private graph: GraphService,
    private authService: MsalService,
    private menu: MenuService
  ) {  }

  ngOnInit(): void {
    this.graph.getUserProfile()
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((result) => { this.user = result; });
    this.graph.getUserPhoto()
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((result) => { this.avatar = result; });
  }
  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  logout() {
    this.menu.reset();
    this.authService.logout();
  }
}
