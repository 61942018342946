export const environment = {
  SERVER_ORIGIN: 'https://portal.uk.om.org',
  groups: {
    user_group_id: "620d26fb-7881-47ea-8013-a92f79389831",
    finance_group_id: "52f0a984-0ceb-42c8-8eab-14c20ba92ac4",
    leader_group_id: "21ccc338-5e26-44dd-acb6-a0b8f45cc7c2",
    admin_group_id: "4e05baa4-9085-49ab-a49e-43713a0a6c1f",
  },
  production: true,
  useHash: false,
  hmr: false,
};
