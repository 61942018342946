import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService, SettingsService } from '@core';
import { GraphService, User } from '@shared';
import { MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-panel',
  template: `
    <div class="matero-user-panel" fxLayout="column" fxLayoutAlign="center center">
      <img class="matero-user-panel-avatar" [src]="avatar" alt="avatar" width="64" />
      <h4 class="matero-user-panel-name">{{ user?.displayName }}</h4>
      <h5 class="matero-user-panel-email">{{ user?.mail }}</h5>
      <div class="matero-user-panel-icons">
        <!--<a routerLink="/profile/overview" mat-icon-button>
          <mat-icon>account_circle</mat-icon>
        </a>
        <a routerLink="/profile/settings" mat-icon-button>
          <mat-icon>settings</mat-icon>
        </a>-->
        <a mat-icon-button (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit, OnDestroy {
  user: User;
  avatar: any;

  // keep reference of all subscriptions, do destroy later
  ngDestroy$ = new Subject();

  constructor(
    private graph: GraphService, 
    private authService: MsalService,
    private menu: MenuService
  ){  }

  ngOnInit(): void {
    this.graph.getUserProfile()
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((result) => { this.user = result; });
    this.graph.getUserPhoto()
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((result) => { this.avatar = result; });

  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  logout() {
    this.menu.reset();
    this.authService.logout();
  }
}
