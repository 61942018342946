import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxPermissionsModule } from 'ngx-permissions';
import { MaterialModule } from '../material.module';
import { MaterialExtensionsModule } from '@ng-matero/extensions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { ExpanderComponent } from './components/expander/expander.component';

import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';
import { WithLoadingPipe } from '@shared/pipes/with-loading.pipe';
import { StatusPipe } from '@shared/pipes/status.pipe';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { NewsComponent } from './components/news/news.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const THIRD_MODULES = [
  NgxPermissionsModule.forChild(),
  MaterialModule,
  MaterialExtensionsModule,
  FlexLayoutModule,
  NgProgressModule,
  NgProgressRouterModule,
  NgProgressHttpModule,
  NgxCurrencyModule,
  NgxMaskModule,
  NgSelectModule,
  FormlyModule,
  FormlyMaterialModule,
  FormlyMatDatepickerModule,
  ToastrModule,
  TranslateModule,
];
const COMPONENTS = [BreadcrumbComponent, PageHeaderComponent, ErrorCodeComponent, ExpanderComponent, ImageViewerComponent, NewsComponent];
const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [];
const PIPES = [SafeUrlPipe, WithLoadingPipe, StatusPipe];

@NgModule({
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES],
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, ...THIRD_MODULES],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ...THIRD_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class SharedModule {}
