import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NewsRss } from './news-rss';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  RssData: NewsRss;
  loading: boolean = true;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.GetRssFeedData();
  }

  GetRssFeedData() {
    const requestOptions: Object = {
      observe: 'body',
      responseType: 'text',
    };
    const _url = 'https://portal.uk.om.org/api/rss';
    this.http.get<any>(_url, requestOptions).subscribe(data => {
      let parseString = require('xml2js').parseString;
      parseString(data, (err, result: NewsRss) => {
        this.RssData = result;
        this.loading = false;
      });
    });
  }

  getDataDiff(endDate) {
    let setDate= new Date(endDate).toISOString();
    var diff = (new Date()).getTime() - new Date(setDate).getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    let dayString =  days == 0 ?  "" : days + "days ";
    let hoursString =  hours == 0 ?  "" : hours + "hr ";
    let minutesString =  minutes == 0 ?  "" : minutes + "m ";
    return dayString + hoursString + minutesString;
  }
}
