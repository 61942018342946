<h2 *ngIf="(finance && edit_mode) || edit_mode" mat-dialog-title>Edit Reimbursement R{{"000000" + model.reimbursement.id |  slice:-6}}</h2>
<h2 *ngIf="(finance && view_mode) || view_mode" mat-dialog-title>View Reimbursement R{{"000000" + model.reimbursement.id |  slice:-6}}</h2>
<h2 *ngIf="authorise" mat-dialog-title>Authorise Reimbursement R{{"000000" + model.reimbursement.id |  slice:-6}}</h2>
<h2 *ngIf="add_mode" mat-dialog-title>Add Reimbursement</h2>
<mat-dialog-content>
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center" fxFill>
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <mat-expansion-panel *ngIf="view_mode || authorise || finance">
      <mat-expansion-panel-header>
        <mat-panel-title>
          History
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <mat-list-item [ngClass]="row" *ngFor="let s of model.reimbursement.status; last as last">
          <span matLine>
            <span fxFlex="30%">{{s.date | date:'dd/MM/yyyy HH:mm'}}</span>
            <span fxFlex="40%">{{s.type | status}}</span>
            <span fxFlex="30%">{{s.user}}</span>
          </span>
          <span matLine *ngIf="s.notes">{{s.notes}}</span>
          <mat-divider [inset]="true" *ngIf="!last"></mat-divider>  
      </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
    <form [formGroup]="form">
      <formly-form
        [form]="form"
        [options]="options"
        [model]="model"
        [fields]="fields">
      </formly-form>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="edit_mode || add_mode || finance" mat-button (click)="onCancel()">Cancel</button>
  <button *ngIf="(view_mode || authorise) && !finance" mat-button (click)="onCancel()">Close</button>
  <button *ngIf="!loading && ((edit_mode || add_mode) && !finance)" mat-button (click)="onSubmit()" [disabled]="!form.valid">Submit</button>
  <button *ngIf="!loading && (view_mode && submit)" mat-button (click)="onSubmit()">Submit</button>
  <button *ngIf="!loading && (edit_mode || add_mode)" mat-raised-button color="primary" (click)="onSave()" [disabled]="!form.valid">Save</button>
  <button *ngIf="!loading && (authorise || (view_mode && finance))" mat-button (click)="onDecline()">Decline</button>
  <button *ngIf="!loading && authorise" mat-raised-button color="primary" (click)="onAuthorise()">Authorise</button>
  <button *ngIf="!loading && (view_mode && finance)" mat-raised-button color="primary" (click)="onProcess()">Process</button>
</mat-dialog-actions>