import { PageRequest, Page } from '../page';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { Partner } from '../models/finance/partner';
import { ServiceResponse } from '../ServiceResponse';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SelectOption } from '../models/SelectOption';

export interface PartnerQuery {
  key: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.SERVER_ORIGIN;
    this.myApiUrl = 'api/partner';
  }

  page(request: PageRequest<Partner>, query: PartnerQuery): Observable<Page<Partner>> {
    var params: HttpParams = new HttpParams();

    var filters = [];
    if (query.key) {
      query.key = query.key.toLowerCase();
      filters.push('key@=' + query.key);
    }
    if (query.name) {
      query.name = query.name.toLowerCase();
      filters.push('name@=' + query.name);
    }
    if (filters.length){
      params = params.set('filters', filters.join(', '));
    }
    
    if (request.page > 0) {
      params = params.set('page', (request.page + 1).toString());
    }

    if (request.size > 0) {
      params = params.set('pageSize', request.size.toString());
    }

    if (request.sort) {
      params = params.set(
        'sorts',
        (request.sort.direction == 'asc' ? '' : '-') + request.sort.active.toString()
      );
    }

    return this.http
      .get<ServiceResponse<Partner[]>>(`${this.myAppUrl}/${this.myApiUrl}/getAll`, { params })
      .pipe(
        map(response => {
          const page: Page<Partner> = {
            content: response.data,
            number: response.page - 1,
            size: response.pageSize,
            totalElements: response.count,
          };
          return page;
        })
      );
  }

  add(partner: Partner): Observable<Partner> {
    return this.http
      .post<ServiceResponse<Partner>>(
        `${this.myAppUrl}/${this.myApiUrl}`, 
        JSON.stringify(partner), 
        this.httpOptions)
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  update(partner: Partner): Observable<Partner> {
    return this.http.put<ServiceResponse<Partner>>(
        `${this.myAppUrl}/${this.myApiUrl}`, 
        JSON.stringify(partner), 
        this.httpOptions
      )
      .pipe(
        retry(1),
        map(result => result.data)
      );
  }

  delete(partner: Partner): Observable<Partner> {
    return this.http.delete<ServiceResponse<Partner>>(`${this.myAppUrl}/${this.myApiUrl}/${partner.id}`)
      .pipe(
        retry(1),
        map(result => partner)
      );
  }

  getOptions(): Observable<SelectOption[]> {
    return this.http
      .get<ServiceResponse<SelectOption[]>>(`${this.myAppUrl}/${this.myApiUrl}/getOptions`)
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }

  getUserPartner(userId: Number): Observable<Partner> {
    return this.http
      .get<ServiceResponse<Partner>>(`${this.myAppUrl}/${this.myApiUrl}/getUserPartner/${userId}`)
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }
}
