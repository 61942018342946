import { Costcentre } from "./costcentre";
import { Account } from "./account";
import { Receipt } from "./receipt";

export class Invoice {
  constructor(
    public id?: number,
    public date: Date = new Date( ),
    public description?: string,
    public costcentre?: Costcentre,
    public account?: Account,
    public amount?: number,
    public receipts: Array<Receipt> = [ ],
  ) { }
}