import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaginatedDataSource } from '@shared/data/paginated-datasource';
import { Sort } from '@angular/material/sort';
import { Reimbursement } from '@shared/data/models/finance/reimbursement';
import { ReimbursementService, ReimbursementQuery} from '@shared/data/services/reimbursement.service';
import { ReimbursementsEditComponent } from '../reimbursements/edit/edit.component';
import { MtxGridColumn } from '@ng-matero/extensions';
import { MtxDialog } from '@ng-matero/extensions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './reimbursements.component.html',
  styleUrls: ['./reimbursements.component.css'],
})
export class ReimbursementsComponent implements OnInit, OnDestroy {
  displayedColumns = ['id', 'actions'];
  initialSort: Sort = { active: 'updatedDate', direction: 'desc' };
  data: PaginatedDataSource<Reimbursement, ReimbursementQuery>;
  loading: boolean = true;
 
  // keep reference of all subscriptions, do destroy later
  ngDestroy$ = new Subject();

  // Values for filtering
  show_filter: boolean;
  filter_code: string;
  filter_name: string;

  columns: MtxGridColumn[] = [
    {
      header: '#',
      field: 'id',
      sortable: true,
      formatter: (data: any) => 'R' + ('000000' + data.id).slice(-6),
    },
    {
      header: 'Status',
      field: 'status',
      formatter: (data: any) => this.reimbursements.getStatusText(data),
    },
    {
      header: 'Last updated',
      field: 'updatedDate',
      sortable: true,
      formatter: (data: any) => formatDate(data.updatedDate, 'dd/MM/yyyy, HH:mm', 'en'),
    },
    {
      header: '',
      field: 'option',
      pinned: 'right',
      right: '0px',
      width: '120px',
      type: 'button',
      buttons: [
        {
          icon: 'search',
          tooltip: 'View',
          type: 'icon',
          click: record => this.view(record.id),
        },
        {
          icon: 'edit',
          tooltip: 'Edit',
          type: 'icon',
          iif: (data: any) => this.reimbursements.canEdit(data),
          click: record => this.edit(record.id),
        },
        {
          icon: 'edit',
          tooltip: 'Edit',
          type: 'icon',
          iif: (data: any) => !this.reimbursements.canEdit(data),
          disabled: true,
        },
        {
          icon: 'delete',
          tooltip: 'Delete',
          type: 'icon',
          iif: (data: any) => this.reimbursements.canEdit(data),
          click: record => this.delete(record),
        },
        {
          icon: 'delete',
          tooltip: 'Delete',
          type: 'icon',
          iif: (data: any) => !this.reimbursements.canEdit(data),
          disabled: true,
        },
      ],
    },
  ];

  constructor(
    private reimbursements: ReimbursementService,
    private mtxDialog: MtxDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.data = new PaginatedDataSource<Reimbursement, ReimbursementQuery>(
      (request, query) => this.reimbursements.page(request, query),
      this.initialSort,
      { code: '', name: '' },
      10
    );

    let viewId = this.router.url.split('/')[3];
    if(viewId){
      this.view(Number(viewId));
    }

    this.data.loading$.subscribe(val => (this.loading = val));
  }

  edit(id: number) {
    const dialogRef = this.mtxDialog.originalOpen(ReimbursementsEditComponent, {
      disableClose: true,
      autoFocus: true,
      width: '800px',
      data: { id: id, edit: true },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.data.refresh();
        if (result.status[result.status.length - 1].type == 2) {
          this.snackBar.open(`Reimbursement ${result.id} submitted.`, 'Dismiss', {
            duration: 3000,
          });
        } else {
          this.snackBar.open(`Reimbursement ${result.id} saved.`, 'Dismiss', { duration: 3000 });
        }
      }
    });
  }

  view(id: number) {
    const dialogRef = this.mtxDialog.originalOpen(ReimbursementsEditComponent, {
      disableClose: true,
      autoFocus: true,
      width: '800px',
      data: { id: id, edit: false },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.data.refresh();
        if (result.status[result.status.length - 1].type == 2) {
          this.snackBar.open(`Reimbursement ${result.id} submitted.`, 'Dismiss', {
            duration: 3000,
          });
        } else {
          this.snackBar.open(`Reimbursement ${result.id} saved.`, 'Dismiss', { duration: 3000 });
        }
      }
    });
  }

  add() {
    const dialogRef = this.mtxDialog.originalOpen(ReimbursementsEditComponent, {
      disableClose: true,
      autoFocus: true,
      width: '800px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.data.refresh();
        if (result.status[result.status.length - 1].type === 2) {
          this.snackBar.open(`Reimbursement ${result.id} submitted.`, 'Dismiss', {
            duration: 3000,
          });
        } else {
          this.snackBar.open(`Reimbursement ${result.id} saved.`, 'Dismiss', { duration: 3000 });
        }
      }
    });
  }

  delete(reimbursement: Reimbursement) {
    this.mtxDialog.open({
      title: 'Delete reimbursement',
      description: `Do you want to delete reimbursement with code: ${reimbursement.id}?`,
      width: '450px',
      buttons: [
        {
          text: 'Cancel',
          onClick: () => {},
        },
        {
          color: 'primary',
          focusInitial: true,
          text: 'Delete',
          onClick: () => {
            this.reimbursements.delete(reimbursement).subscribe(result => {
              this.data.refresh();
              this.snackBar.open(`Reimbursement ${result.id} deleted.`, 'Dismiss', {
                duration: 3000,
              });
            });
          },
        },
      ],
    });
  }

  filter() {
    this.show_filter = !this.show_filter;
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
