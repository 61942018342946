<page-header></page-header>
<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px">
  <div fxFlex="50">
    <mat-dialog-content>
      <h2>Welcome {{ user?.givenName }} {{ user?.surname }}!</h2>
      <p>Go to <a routerLink="/reimbursements">Reimbursements</a> to manage your Reimbursement Requests.</p>
      <p>Go to <a routerLink="/staff-tools/signature">Signature</a> to generate an OM UK Email Signature.</p>
      <p>&nbsp;</p>
      <p>Download Mileage Claim sheets (<a href="/assets/MileageClaim.xlsx">Excel</a>, <a href="/assets/MileageClaim.pdf">PDF</a>).</p>
      <p>&nbsp;</p>

      <h4>Please email us for any questions:</h4>
      <p>For financial questions send an email to <a href="mailto:Finance.UK@om.org">Finance.UK@om.org</a></p>
      <p>For IT questions send an email to <a href="mailto:IT.UK@om.org">IT.UK@om.org</a></p>
    </mat-dialog-content>
  </div>
  <div fxFlex="50">
    <app-news></app-news>
  </div>
</div>
