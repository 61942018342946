<h2>OM News:</h2>
<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center" fxFill>
  <mat-spinner diameter="50"></mat-spinner>
</div>
<div *ngIf="!loading" fxLayout="row wrap" fxLayoutGap="10px grid">
  <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let o of RssData?.rss.channel[0].item">
    <mat-card>
      <img mat-card-image src="{{ o['om:attachedPhotoUrl'][0] }}" />
      <mat-card-title><a href="{{ o.link[0] }}">{{ o.title[0] }}</a></mat-card-title>
      <mat-card-content>
        <p>{{ o.description[0] }}</p>
      </mat-card-content>
      <mat-card-action>
        <a mat-raised-button color="primary" href="{{ o.link[0] }}" target="_blank">Read article</a>
      </mat-card-action>
    </mat-card>
  </div>
</div>
