import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'formly-field-currency',
  template: `
    <input
      matInput
      currencyMask 
      [id]="id"
      [type]="type || 'text'"
      [readonly]="to.readonly"
      [required]="to.required"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [tabIndex]="to.tabindex"
      [placeholder]="to.placeholder"
      [options]="{ prefix: '£ ', allowNegative: false, inputMode: 1 }"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldCurrencyComponent extends FieldType {
  get type() {
    return this.to.type || 'text';
  }
}