<nav aria-label="breadcrumb">
  <ol class="matero-breadcrumb">
    <li class="matero-breadcrumb-item"
        *ngFor="let navlink of nav; trackBy: trackByNavlink; first as isFirst;">
      <a href="#" class="link" *ngIf="isFirst">{{navlink}}</a>
      <ng-container *ngIf="!isFirst">
        <mat-icon class="chevron">chevron_right</mat-icon>
        <span class="link">{{navlink | translate}}</span>
      </ng-container>
    </li>
  </ol>
</nav>
