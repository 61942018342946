import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
  constructor() {}
  transform(status: number) {
    switch (status) {
      case 1: // STATUS_USER_SAVED
        return 'Reimbursement saved.';
      case 2: // STATUS_USER_SUBMITTED
        return 'Reimbursement submitted.';
      case 3: // STATUS_LEADER_AUTHORISED
        return 'Reimbursement authorised.';
      case 4: // STATUS_LEADER_REJECTED
        return 'Reimbursement declined.';
      case 5: // STATUS_FINANCE_PROCESSED
        return 'Reimbursement processed.';
      case 6: // STATUS_FINANCE_DECLINED
        return 'Reimbursement declined.';
      case 7: // STATUS_FINANCE_EDITED
        return 'Edited by Finance.';
    }
  }
}
