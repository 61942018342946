import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'expander',
    template: `
        <div class="header">
            <span class="toggle noselect" (click)="toggle()" [attr.title]="title" (dblclick)="$event.stopPropagation()">
                <mat-icon class="menu-icon">{{iconClass}}</mat-icon><span class="menu-name">{{heading}}</span>
            </span>
        </div>
        <div class="content" [class.expanded]="showContent">
            <ng-content></ng-content>
        </div>
    `,
    styles: [`
        :host {
            margin-top: 1em;
        }
        .header {
            font-weight: bold;
        }
        .toggle {
            cursor: pointer;
            cursor: hand;
        }
        .noselect {
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .content {
            display: none;
            padding: 1em 0;
        }
        .content.expanded {
            display: block;
        }
        .menu-icon {
            width: 18px;
            height: 18px;
            margin-right: 16px;
            font-size: 18px;
            line-height: 18px;
        }
    `]
})
export class ExpanderComponent implements OnChanges, OnInit {
    private static expandedState = {
        title: 'Select to collapse',
        iconClass: 'expand_less'
    };

    private static collapsedState = {
        title: 'Select to expand',
        iconClass: 'expand_more'
    };

    @Input() heading: string;
    @Input() expanded: boolean;

    title: string;
    iconClass: any;

    private _showContent: boolean;

    get showContent(): boolean {
        return this._showContent;
    }

    set showContent(value: boolean) {
        this._showContent = !!value;
        this.onShowContentChanged();
    }

    ngOnInit(): void {
        this.showContent = this.expanded;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.expanded) {
            this.showContent = this.expanded;
        }
    }

    toggle(): void {
        this.showContent = !this.showContent;
    }

    private onShowContentChanged(): void {
        this.title = this.showContent ? ExpanderComponent.expandedState.title : ExpanderComponent.collapsedState.title;
        this.iconClass = this.showContent ? ExpanderComponent.expandedState.iconClass : ExpanderComponent.collapsedState.iconClass;
    }
}