import { Component, OnInit, Inject } from '@angular/core';
import { Reimbursement } from '@shared/data/models/finance/reimbursement';
import { ReimbursementService } from '@shared/data/services/reimbursement.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonService, CostcentreService, AccountService } from '@shared';
import { MtxDialog } from '@ng-matero/extensions';
import { ReimbursementsNotesComponent } from './notes.component';
import { ReimbursementsProcessComponent } from './process.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reimbursements-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class ReimbursementsEditComponent implements OnInit {
  edit_mode: boolean = false;
  view_mode: boolean = false;
  add_mode: boolean = false;
  authorise: boolean = false;
  finance: boolean = false;
  submit: boolean = false;
  loading: boolean = true;

  form = new FormGroup({});
  model: any = { reimbursement: new Reimbursement() };
  options: FormlyFormOptions = {
    formState: {
      disabled: false,
      finance: false,
    },
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'reimbursement',
      fieldGroup: [
        {
          key: 'id',
          type: 'input',
          templateOptions: {
            label: 'Id',
          },
          hide: true,
        },
        {
          key: 'leader',
          type: 'select',
          wrappers: ['panel', 'form-field'],
          templateOptions: {
            text: 'Select a leader to authorise this request.', // Will be used in Panel wrapper
            width: '300px',
            label: 'Leader',
            placeholder: 'Leader',
            required: true,
            multiple: false,
            options: this.getLeaderOptions(),
            valueProp: o => o.id,
            labelProp: o => o.name,
          },
          expressionProperties: {
            'templateOptions.disabled': 'formState.disabled',
          },
        },
      ],
    },
    {
      key: 'reimbursement',
      wrappers: ['expander'],
      templateOptions: {
        label: 'Invoices',
      },
      fieldGroup: [
        {
          key: 'invoices',
          type: 'repeat-section',
          templateOptions: {
            sectionTitle: 'Invoice',
            description: this.model.reimbursement.invoices,
            addItem: 'Add Invoice',
            required: true,
          },
          expressionProperties: {
            'templateOptions.disabled': 'formState.disabled',
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'id',
                type: 'input',
                templateOptions: {
                  label: 'Id',
                },
                hide: true,
              },
              {
                fieldGroupClassName: 'row',
                fieldGroup: [
                  {
                    key: 'date',
                    type: 'datepicker',
                    className: 'col-sm-3',
                    templateOptions: {
                      label: 'date',
                      required: true,
                      datepickerOptions: {
                        startView: 'month',
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                    },
                    defaultValue: new Date(),
                  },
                  {
                    key: 'description',
                    type: 'input',
                    className: 'col',
                    templateOptions: {
                      label: 'Description',
                      maxLength: 255,
                      required: true,
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.finance',
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'row',
                fieldGroup: [
                  {
                    key: 'costcentre',
                    type: 'filter-select',
                    className: 'col',
                    templateOptions: {
                      label: 'Costcentre',
                      required: true,
                      options: this.costcentres.getOptions(),
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.finance',
                    },
                  },
                  {
                    key: 'account',
                    type: 'filter-select',
                    className: 'col',
                    templateOptions: {
                      label: 'Account',
                      required: true,
                      options: this.accounts.getOptions(),
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.finance',
                    },
                  },
                  {
                    key: 'amount',
                    type: 'currency',
                    className: 'col-sm-3',
                    templateOptions: {
                      label: 'Amount',
                      required: true,
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'row',
                fieldGroup: [
                  {
                    key: 'receipts',
                    type: 'receipts',
                    className: 'col',
                    templateOptions: {
                      label: 'Receipts',
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                    },
                    fieldArray: {
                      fieldGroup: [
                        {
                          key: 'fileId',
                          type: 'input',
                          defaultValue: 'fieldid',
                          templateOptions: {
                            label: 'FileId:',
                          },
                          hide: true,
                        },
                        {
                          key: 'filename',
                          defaultValue: 'filename',
                          type: 'input',
                          templateOptions: {
                            label: 'Filename:',
                          },
                          hide: true,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ];

  constructor(
    private mtxDialog: MtxDialog,
    private dialogRef: MatDialogRef<ReimbursementsEditComponent>,
    private reimbursements: ReimbursementService,
    private persons: PersonService,
    private costcentres: CostcentreService,
    private accounts: AccountService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (this.data) {
      if (this.data.edit) {
        this.edit_mode = true;
      } else if (this.data.authorise) {
        this.authorise = true;
        this.options.formState.disabled = true;
        this.options.formState.finance = true;
      } else {
        this.view_mode = true;
        this.options.formState.disabled = true;
        this.options.formState.finance = true;
      }

      if (this.data.finance) {
        this.finance = true;
        this.options.formState.disabled = true;
        if (this.edit_mode) {
          this.options.formState.finance = false;
        } else {
          this.options.formState.finance = true;
        }
      }

      this.reimbursements.get(this.data.id).subscribe(result => {
        this.model = { ...this.model, reimbursement: result };
        this.submit =
          this.model.reimbursement.status[this.model.reimbursement.status.length - 1].type == 1;
          this.loading = false;
      });
    } else {
      this.add_mode = true;
      this.loading = false;
    }
  }

  getLeaderOptions() {
    if (this.data && (!this.data.edit || this.data.authorise || this.data.finance)) {
      return this.persons.getOptions();
    } else {
      return this.persons.getLeaderOptions();
    }
  }

  ngOnInit() {}

  onSave() {
    if (this.form.valid) {
      this.loading = true;
      var reimbursement: Reimbursement = <Reimbursement>this.model.reimbursement;
      if (this.finance) {
        const dialogRef = this.mtxDialog.originalOpen(ReimbursementsNotesComponent, {
          disableClose: true,
          autoFocus: true,
          width: '800px',
          data: { header: 'Reason for editing request:' },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.reimbursements.update(reimbursement, result).subscribe(reimbursement => {
              this.dialogRef.close(reimbursement);
            });
          }
        });
      } else {
        if (reimbursement.id) {
          this.reimbursements.update(reimbursement).subscribe(reimbursement => {
            this.dialogRef.close(reimbursement);
          });
        } else {
          this.reimbursements.add(reimbursement).subscribe(reimbursement => {
            this.dialogRef.close(reimbursement);
          });
        }
      }
    }
  }

  onSubmit() {
    if (this.form.valid || (this.view_mode && this.submit)) {
      var reimbursement: Reimbursement = <Reimbursement>this.model.reimbursement;
      if(!this.checkReceipts(reimbursement)){
        this.mtxDialog.confirm(
          'One or more invoices have no receipts attached. Do you want to continue?',
          '',
          () => {
            this.reimbursements.submit(reimbursement).subscribe(reimbursement => {
              this.dialogRef.close(reimbursement);
            });
          },
          () => {}
        );
      } else {
        this.reimbursements.submit(reimbursement).subscribe(reimbursement => {
          this.dialogRef.close(reimbursement);
        });
      }
    }
  }

  private checkReceipts(reimbursement: Reimbursement): boolean{
    for(let invoice of reimbursement.invoices){
      if(invoice.receipts.length == 0){
        return false;
      }
    }
    return true;
  }

  onCancel() {
    this.dialogRef.close();
  }

  onAuthorise() {
    if (this.form.valid || this.authorise) {
      var reimbursement: Reimbursement = <Reimbursement>this.model.reimbursement;
      this.reimbursements.authorise(reimbursement).subscribe(reimbursement => {
        this.dialogRef.close(reimbursement);
      });
    }
  }

  onDecline() {
    if (this.form.valid || this.authorise || this.finance) {
      var reimbursement: Reimbursement = <Reimbursement>this.model.reimbursement;

      const dialogRef = this.mtxDialog.originalOpen(ReimbursementsNotesComponent, {
        disableClose: true,
        autoFocus: true,
        width: '800px',
        data: { header: 'Reason for declining request:' },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.reimbursements
            .decline(reimbursement, result, this.finance)
            .subscribe(reimbursement => {
              this.dialogRef.close(reimbursement);
            });
        }
      });
    }
  }

  onProcess() {
    var reimbursement: Reimbursement = <Reimbursement>this.model.reimbursement;

    const dialogRef = this.mtxDialog.originalOpen(ReimbursementsProcessComponent, {
      disableClose: true,
      autoFocus: true,
      width: '800px',
      data: { reimbursement: reimbursement },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reimbursements.process(reimbursement, result).subscribe(reimbursement => {
          
          this.dialogRef.close(reimbursement);
        });
      }
    });
  }
}
